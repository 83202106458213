<template>
  <div class="top-bar">
    <div class="user__profile">
      <div class="user">
        <span>{{ userName }}</span>
        <!-- prettier-ignore -->
        <svg class="arrow" width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
        <div class="user__dropdown">
          <span class="user__dropdown-item" @click="signOut">Sign out</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/')
        })
    },
  },
  computed: {
    ...mapGetters(['userName']),
  },
}
</script>

<style lang="scss" scoped>
.top-bar {
  padding: 1.5rem 0;
  background: #faf9f9;
}
.user__profile {
  display: flex;
  align-items: center;
}
.user {
  margin-left: auto;
  display: flex;
  color: #474746;
  align-items: center;
  position: relative;
  cursor: pointer;
  svg {
    margin-right: 1rem;
  }
  .arrow {
    margin-left: 0.5rem;
  }
  .user__dropdown {
    display: none;
    position: absolute;
    margin: 0;
    overflow: hidden;
    background-color: white;
    z-index: 1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    min-width: 160px;
    top: calc(100%);
    right: 0;
    & .user__dropdown-item {
      display: flex;
      border-bottom: 1px solid #f4f4f4;
      padding: 0.5rem 1rem;
      margin-right: 0;
      color: #4d4f5c;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
  &:hover .user__dropdown {
    display: block;
  }
}
.dropdown {
  margin-right: 2rem;
  margin-left: auto;
  cursor: pointer;
}
.dropdown:hover {
  display: flex;
  flex-direction: column;
}
.content-wrapper {
  position: relative;
  display: grid;
  gap: 1.5rem;
  grid-template-rows: auto 1fr;
  align-items: start;
  padding: 2rem 3rem;
  min-height: calc(100vh - 70px);
  grid-template-columns: auto;
  box-sizing: border-box;
}
</style>
