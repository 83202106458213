<template>
  <div class="admin">
    <template v-if="isLoggedIn">
      <AdminNavigation></AdminNavigation>
      <div class="admin-container">
        <TopBar></TopBar>
        <router-view></router-view>
      </div>
    </template>
    <div v-if="!isLoggedIn" id="firebaseui-auth-container"></div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

import { mapActions, mapState } from 'vuex'

import AdminNavigation from '@/components/admin/AdminNavigation.vue'
import TopBar from '@/components/admin/TopBar.vue'

export default {
  name: 'Admin',
  components: {
    AdminNavigation,
    TopBar,
  },
  async created() {
    await this.startFirebaseAuth()
    await this.getProjects()
  },
  methods: {
    ...mapActions(['getProjects']),
    async startFirebaseAuth() {
      if (!this.isLoggedIn) {
        var uiConfig = {
          signInSuccessUrl: '/admin/calculator-builder',
          signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
        }
        let ui = (await firebaseui.auth.AuthUI.getInstance()) || (await new firebaseui.auth.AuthUI(firebase.auth()))
        ui.start('#firebaseui-auth-container', uiConfig)
      }
    },
  },
  computed: {
    ...mapState(['user']),
    isLoggedIn() {
      return this.user.user !== null
    },
  },
  beforeDestroy() {
    this.$store.commit('resetState')
  },
}
</script>

<style lang="scss">
.mdl-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.admin {
  display: grid;
  grid-template-columns: 200px 1fr;
  padding: 1.5rem;
}
.admin-container {
  margin-left: 1.5rem;
}
.admin-wrapper {
  display: grid;
  grid-gap: 2rem;
  margin-top: 1.5rem;
  h1 {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: #333;
  }
}
</style>
